<template>
	<div>
		<section class="section wow">
			<div class="content">
				<Breadcrumb :page-name="'О нас'" />
				<div class="contacts-block">
					<h3 class="page-title">
						О нас
					</h3>
					<div class="grid">
						<div class="box">
							<h4 class="block-title">
								Лифт в будущее
							</h4>
							<div class="text dark md">
								<p>
									Всероссийская программа профориентации и профессионального развития, способствующая
									повышению карьерных возможностей в компаниях АФК «Система».
								</p>
								<p>
									Онлайн платформа «Лифт в будущее» дает школьникам и студентам по всей стране равный и
									бесплатный доступ к сервису профориентации, образовательным курсам и помогает найти
									первую работу. Программа отличается целенаправленной привязкой к практике и к
									работодателям: действующие специалисты компаний Группы АФК «Система» ведут курсы на
									платформе, рассказывают о своих профессиях, рекрутеры Корпорации наблюдают за
									результатами участников, чтобы выявить лучших кандидатов, которым предлагаются
									стажировки и вакансии для молодых специалистов.
								</p>
								<p>
									Проект реализуется благотворительным фондом
									<a
										href="https://bf.sistema.ru/"
										target="_blank"
										style="text-decoration: none;"
									>«Система»</a>
								</p>
							</div>
						</div>
						<div class="box">
							<h4 class="block-title">
								Контакты
							</h4>
							<div class="contacts">
								<div class="contact">
									<div class="info">
										Адрес
									</div>
									Москва, ул.Малая Дмитровка, д.18 стр.1
								</div>
								<div class="contact">
									<div class="info">
										Контактый телефон
									</div>
									<a href="tel:+7(495)737-44-19">+ 7 (495) 737-44-19</a>
								</div>
								<div class="contact">
									<div class="info">
										Электронная почта
									</div>
									<ul>
										<li><a href="mailto:info@mail.lift-bf.ru">info@mail.lift-bf.ru</a></li>
									</ul>
								</div>
								<div class="contact">
									<div class="info">
										Соцсети
									</div>
									<div class="social-contact-group">
										<a
											href="https://vk.com/lift2future"
											target="_blank"
											class="social-contact-item"
										>
											<img
												:src="'/img/icons/icon-contact-vk-2.png' | prodSrcImage"
												alt="Социальная сеть"
											>
										</a>
									</div>
								</div>
							</div>
							<base-button
								v-scroll-to="'#anchor-map'"
								light-border
								transparent
								class="button-hollow"
							>
								Посмотреть на карте
							</base-button>
						</div>
					</div>
					<br>
					<br>
					<h4 class="block-title">
						Партнеры
					</h4>
					<VueSlickCarousel
						class="partners-slider"
						:lazy-load="'progressive'"
						v-bind="partnerSlider"
					>
						<div
							v-for="(slide, index) in partnersSlider"
							:key="index"
							class="slide"
						>
							<a
								:href="slide.link"
								target="_blank"
								class="partner"
							>
								<img
									:src="slide.image | prodSrcImage"
									alt="Партнер"
									class="pic"
								>
							</a>
						</div>
					</VueSlickCarousel>
				</div>
			</div>
		</section>

		<section class="section-simple light wow">
			<div class="contacts-block">
				<div class="content">
					<div class="form-block">
						<h4 class="block-title lg">
							Напишите нам
						</h4>
						<div class="text">
							<p>Ответим в течение суток.</p>
						</div>
						<form
							id="formSendComment"
							class="form"
							novalidate
							@submit.prevent="sendComment"
						>
							<div class="field-wrap">
								<label class="field-label">
									<input
										v-model.trim="$v.first_name.$model"
										type="text"
										class="field"
										placeholder="Имя"
										style="text-transform: capitalize"
									>
									<div class="label">Имя</div>
									<span
										v-if="$v.first_name.$error"
										class="help-block form-error"
									>
										{{ !$v.first_name.required ? 'Это обязательное поле' : !$v.first_name.onlyAlpha ? 'Только буквы' : 'Ошибка поля' }}
									</span>
								</label>
							</div>
							<div class="field-wrap">
								<label class="field-label">
									<input
										v-model.trim="$v.last_name.$model"
										type="text"
										class="field"
										placeholder="Фамилия"
										style="text-transform: capitalize"
									>
									<div class="label">Фамилия</div>
									<span
										v-if="$v.last_name.$error"
										class="help-block form-error"
									>
										{{ !$v.last_name.required ? 'Это обязательное поле' : !$v.last_name.onlyAlpha ? 'Только буквы' : 'Ошибка поля' }}
									</span>
								</label>
							</div>
							<div class="field-wrap">
								<label class="field-label">
									<input
										v-model.trim="$v.email.$model"
										type="email"
										class="field"
										placeholder="Email"
									>
									<div class="label">Email</div>
									<span
										v-if="$v.email.$error"
										class="help-block form-error"
									>
										{{ !$v.email.required ? 'Это обязательное поле' : !$v.email.email ? 'Введите корректный e-mail' : 'Ошибка поля' }}
									</span>
								</label>
							</div>
							<div class="field-wrap">
								<label class="field-label">
									<textarea
										v-model.trim="$v.comment.$model"
										rows="1"
										class="field field--v-resize"
										placeholder="Комментарий"
									/>
									<div class="label textarea-label">Комментарий</div>
									<span
										v-if="$v.comment.$error"
										class="help-block form-error"
									>Это обязательное поле</span>
								</label>
							</div>
							<base-button
								v-if="!isSended"
								id="btnSendMail_6"
								class="button lg"
							>
								Отправить
							</base-button>
							<div
								v-else
								class="button orange lg button-preloader"
							>
								<div class="button-preloader-icons">
									<div class="button-preloader-icon" />
									<div class="button-preloader-icon" />
									<div class="button-preloader-icon" />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>

		<section class="section wow">
			<div
				id="anchor-map"
				class="map"
			>
				<iframe
					src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae6a5b6c76fed4d30d9d8f5a0df4f64792a41bf23ab4b5246a1817192769309c0&amp;source=constructor"
					width="100%"
					height="450"
					frameborder="0"
				/>
			</div>
		</section>
	</div>
</template>

<script>

import {required, email} from 'vuelidate/lib/validators';
import {onlyAlpha} from '@/assets/scripts/custom_validators';
import Breadcrumb from '@/components/Breadcrumb';
import BaseButton from '../components/BaseComponents/BaseButton'

export default {
    components: {
        Breadcrumb,
				BaseButton
    },
    data: () => ({
        isSended: false,
        first_name: '',
        last_name: '',
        email: '',
        comment: '',
        partnersSlider: [
            {
                image: '/img/partners/afk-sistema.jpg',
                link: 'https://sistema.ru/'
            },
            {
                image: '/img/partners/unisender.png',
                link: 'https://www.unisender.com/'
            },
            {
                image: '/img/partners/fakultetus.png',
                link: 'https://www.facultetus.ru/'
            },
            {
                image: '/img/partners/mts-1.jpg',
                link: 'https://mts.ru/'
            },
            {
                image: '/img/partners/segeja.png',
                link: 'https://segezha-group.com/'
            },
            {
                image: '/img/partners/medsi.jpg',
                link: 'https://medsi.ru/'
            },
            {
                image: '/img/partners/stepi-agroholding.png',
                link: 'https://www.ahstep.ru/'
            },
            {
                image: '/img/partners/ozon.png',
                link: 'https://www.ozon.ru/'
            },
            {
                image: '/img/partners/cosmos-hotel-group.png',
                link: 'http://cosmosgroup.ru/'
            },
            {
                image: '/img/partners/mts-bank-2.jpg',
                link: 'https://www.mtsbank.ru/'
            },
            {
                image: '/img/partners/sistema-bioteh.png',
                link: 'https://sistemabiotech.ru/'
            },
            {
                image: '/img/partners/gjeli.jpg',
                link: 'http://gzhel.ru/'
            },
            {
                image: '/img/partners/bisnes-nedvijimosti.jpg',
                link: 'https://sistema-bn.ru/'
            },
            {
                image: '/img/partners/vologodski-tekstilni-kombinat.jpg',
                link: 'http://vologda-textile.com/'
            },
            {
                image: '/img/partners/sistema-capital.png',
                link: 'https://sistema-capital.com/'
            },
            {
                image: '/img/partners/sitronix.png',
                link: 'https://www.sitronics.com/'
            },
            {
                image: '/img/partners/gruppa-etalon.png',
                link: 'https://www.etalongroup.ru/'
            },
            {
                image: '/img/partners/binnofarm-grupp.png',
                link: 'https://aliumpharm.ru/'
            },
            {
                image: '/img/partners/ao-besk.jpg',
                link: 'https://www.bashes.ru/'
            },
            {
                image: '/img/partners/koncept-group.png',
                link: 'https://www.conceptgroup.ru/'
            },
            {
                image: '/img/partners/ewub.jpg',
                link: 'https://www.ewub.lu/'
            }
        ]
    }),
    validations: {
        first_name: {
            required,
            onlyAlpha
        },
        last_name: {
            required,
            onlyAlpha
        },
        email: {
            required,
            email
        },
        comment: {
            required
        },
        validationGroup: ['first_name', 'last_name', 'email', 'comment']
    },
    computed: {
        partnerSlider() {
            return {
                ...this.carouselSettings,
                'slides-to-show': 5,
                'slides-to-scroll': 5
            }
        }
    },
    mounted() {
        this.$scrollTo(document.querySelector('header.header-role'))
    },
    methods: {
        sendComment() {
            this.$v.validationGroup.$touch();
            if (this.$v.validationGroup.$invalid) {
							this.$scrollTo(document.querySelector('#formSendComment'))
            } else {
                this.isSended = true
                this.$store.dispatch('cmn/postMail', {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.email,
                    comment: this.comment
                })
                    .then(() => {
                        this.isSended = false;
                        this.modalOpen('mailSuccess')
                    })
            }
        }
    }

}
</script>

<style lang="less">
@import "../assets/styles/pages/contacts";
.textarea-label {
    top: 40% !important
}
textarea.field:not(:placeholder-shown) + .textarea-label {
    top: -10% !important
}
</style>
